<template>
  <div class="main-content">
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items marginBottom10 flex-wrap">
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="primary" icon="el-icon-plus" @click="handleCreate">新增</el-button>
          </div>
          <el-input size="medium" v-model="searchData.name" :maxlength="100" placeholder="输入供应商名称/联系人/联系电话" class="marginRight10 width300 marginBottom10"/>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset">重置</el-button>
          </div>
        </div>
      </template>

      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" icon="el-icon-edit" @click="handleEdit(row)">编辑</el-button>
        <el-button type="text" size="mini" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
      </template>
    </avue-crud>

    <el-dialog
        :title=" form.id ? '编辑' : '新增' "
        :visible.sync="show"
        v-if="show"
        width="30%"
        :before-close="handleClose">
      <avue-form ref="form"
                 v-model="form"
                 :option="formOption"
                 @submit="handleSubmit">
        <template slot="phone">
          <el-input v-model="form.phone" type="text" maxlength="11"
                    placeholder="最多输入11个字" clearable
                    onkeyup="this.value=this.value.replace(/^(0+)|\D+/g,'')"
                    @blur="changeBlur($event,'phone')">
          </el-input>
        </template>

        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {list, view, add, update, deleteNew} from "@/api/supplier/manage"
export default {
  name: "index",
  data() {
    return {
      searchData: {
        name: ''
      },
      page: {
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option: {
        title: '供应商列表',
        titleSize: 'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: '序号',
        indexWidth: 100,
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: 'center',
        menuAlign: 'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '供应商编号',
            prop: 'code'
          },
          {
            label: '供应商名称',
            prop: 'name'
          },
          {
            label: '联系人',
            prop: 'username'
          },
          {
            label: '联系电话',
            prop: 'phone'
          },
          {
            label: '地址',
            prop: 'address'
          },
          {
            label: '可用蜜豆积分',
            prop: 'honeyNum'
          }
        ]
      },

      show: false,
      form: {
        id: ''
      },
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: '供应商名称',
            prop: 'name',
            span: 20,
            placeholder: '最多输入15个字',
            maxlength: 15,
            rules: [
              {
                required: true,
                message: '请输入供应商名称',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '联系人',
            prop: 'username',
            span: 20,
            placeholder: '最多输入15个字',
            maxlength: 15,
            rules: [
              {
                required: true,
                message: '请输入联系人',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '联系电话',
            prop: 'phone',
            span: 20,
            placeholder: '最多输入11个字',
            rules: [
              {
                required: true,
                message: '请输入联系电话',
                trigger: 'blur'
              },{
                validator: (rule, value, callback) => {
                  if(!/^1[3456789]\d{9}$/.test(value)){
                    callback(new Error('请输入正确的手机号'));
                  }else{
                    callback();
                  }
                },
                trigger: 'blur'
              }
            ]
          },
          {
            label: '地址',
            prop: 'address',
            span: 20,
            placeholder: '最多输入50个字',
            maxlength: 50,
            rules: [
              {
                required: true,
                message: '请输入地址',
                trigger: 'blur'
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    onLoad(){
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
    // 查询
    handleSearch(){
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset(){
      // console.log('重置')
      this.searchData = {
        name: ''
      }
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 新增
    handleCreate(){
      this.form = {
        id: ''
      }
      this.show = true;
    },
    // 编辑
    async handleEdit(row){
      const { data } = await view({ id: row.id })
      this.form = {
        id: row.id,
        ...data
      }
      this.show = true;
    },
    // 删除
    handleDelete(row){
      this.$confirm('是否删除此供应商数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteNew({
          id: row.id
        }).then(res => {
          if(res.code === 200){
            this.$message.success('删除成功');
            this.onLoad();
          }else{
            this.$message.error(res.msg);
          }
        })
      })
    },
    // 新增提交
    handleSubmit(form,done){
      console.log('form',form)
      if(form.id){
        update(form).then(res=>{
          if(res.code === 200){
            setTimeout(()=>{
              this.resetForm()
            },100)
            this.$message.success('修改成功')
            this.show = false;
            this.onLoad()
          }else{
            this.$message.success(res.msg)
            done()
          }
        }).catch(()=> done())
      }else{
        add(form).then(res=>{
          if(res.code === 200){
            setTimeout(()=>{
              this.resetForm()
            },100)
            this.$message.success('添加成功')
            this.show = false;
            this.onLoad()
          }else{
            this.$message.success(res.msg)
            done()
          }
        }).catch(()=> done())
      }
    },
    // 关闭弹框
    handleClose(){
      this.show = false;
      setTimeout(()=>{
        this.resetForm()
      },100)
    },
    // 重置form
    resetForm(){
      this.form = {
        id: ''
      }
    },
    changeBlur(e,text){
      const value = e.target.value;
      if(value){
        if(isNaN(e.target.value)){
          e.target.value = ''
          this.form[text] = ''
        }else{
          this.form[text] = value
        }
      }else{
        e.target.value = '';
        this.form[text] = ''
      }
    },
  }
}
</script>

<style scoped>

</style>