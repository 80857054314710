import {Delete, get, post, put} from "@/api/axios";

export const list = params => get('/web/supplier/manage/list', params)

/**
 * /web/supplier/manage/view/{id} get
 */
export const view = params => get('/web/supplier/manage/view/' + params.id, '')

/**
 * /web/supplier/manage/add post
 */
export const add = params => post('/web/supplier/manage/add', params)

/**
 * /web/supplier/manage/update put
 */
export const update = params => put('/web/supplier/manage/update', params)

/**
 * /web/supplier/manage/delete/{id} delete
 */
export const deleteNew = params => Delete('/web/supplier/manage/delete/' + params.id, '')